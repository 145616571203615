.rui-component-loading {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

.rui-component-loading-spinner {
  height: 200px;
}

.rui-no-project {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.rui-no-project-block {
  max-width: 400px;
}

.rui-no-project-controls {
  text-align: center;
}